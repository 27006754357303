import React from 'react';
import {
  Container,
} from 'reactstrap';
import { graphql } from 'gatsby';
import ScrollAnim from 'rc-scroll-anim';
import Layout from '../components/layout';
import FeatureFilm from '../components/FeatureFilm';
import AboutUs from '../components/AboutUs';
import ShortFilms from '../components/ShortFilms';

const { Element } = ScrollAnim;

const IndexPage = ({ data }) =>
  /* const posts = data.allMarkdownRemark.edges.filter(post => !post.node.frontmatter.hidden && post.node.frontmatter.contentType === 'blog'); */
  (
    <Layout>
      <Element id="sectionFeatureFilm">
        <FeatureFilm />
      </Element>
      <Element id="sectionAboutUs">
        <Container>
          <AboutUs />
        </Container>
      </Element>
      <Element id="sectionShortFilms">
        <ShortFilms />
      </Element>
    </Layout>
  );
export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          frontmatter {
            title
            contentType
            date(formatString: "MMMM DD, YYYY")
            path
            hidden
          }
        }
      }
    }
  }
`;
