import React from 'react';
import Link from 'gatsby-link';
import { Container } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import './ShortFilms.scss';

const ShortFilms = () => (
  <section className="section-short-films">
    <Container>
      <Fade top>
        <h3 className="title">
          <span>
            <strong>
              FILMS
            </strong>
          </span>
        </h3>
      </Fade>
      <div className="section-inner">
        <div className="card-shortfilm">
          <div className="img-holder">
            <Fade left>
              <Link to="/cocoon-shell">
                <img loading="lazy" src="/files/poster-film-the-yellow-cocoon-shell.jpg" alt="INSIDE THE YELLOW COCOON SHELL – BÊN TRONG VỎ KÉN VÀNG (2023)"/>
              </Link>
            </Fade>
          </div>
          <div className="txt-holder">
            <h4 className="film-title">
              <Link to="/cocoon-shell">
              INSIDE THE YELLOW COCOON SHELL – BÊN TRONG VỎ KÉN VÀNG (2023)
              </Link>
            </h4>
            <div className="d-none d-sm-none d-md-block mb-4">
              <p><strong>Language:</strong> Vietnamese</p>
              <p><strong>Release Date:</strong> 17 May 2023 (France)</p>
              <p><strong>Director:</strong> Pham Thien An</p>
              <p><strong>Writer:</strong> Pham Thien An</p>
              <p>
                <strong>Producer:</strong> Tran Van Thi, Jeremy Chua
              </p>
              <p><strong>Key Cast:</strong> Le Phong Vu, Nguyen Thinh, Nguyen Thi Truc Quynh, Vu Ngoc Manh</p>
            </div>
          </div>
        </div>
      </div>
      <Fade top>
        <h3 className="title">
          <span>
            <strong>
              SHORT FILMS
            </strong>
          </span>
        </h3>
      </Fade>
      <div className="section-inner">
        <div className="card-shortfilm">
          <div className="d-none d-sm-block">
            <div className="txt-holder">
              <h4 className="film-title">
                <Link to="/stayawake">
                  STAY AWAKE, BE READY HÃY TỈNH THỨC VÀ SẴN SÀNG (2019)
                </Link>
              </h4>
              <div className="d-none d-sm-none d-md-block mb-4">
                <p><strong>Language:</strong> Vietnamese</p>
                <p><strong>Release Date:</strong> 23 May 2019 (France) </p>
                <p><strong>Director:</strong> Pham Thien An</p>
                <p><strong>Writer:</strong> Pham Thien An</p>
                <p><strong>Stars:</strong> Trung Dong Cao, Be Hieu, Vu Trong Tuyen</p>
              </div>
              <div className="text-white font-size-big">
                On a street corner a mysterious conversation among three young men at a street stalls. Meanwhile a traffic accident on a motorbike. The night brings together a sketch, a multi color frame of reality.
              </div>
            </div>
          </div>
          <div className="img-holder">
            <Fade right>
              <Link to="/stayawake">
                <img loading="lazy" src="/files/poster-film-stay-awake-be-ready.jpg" alt="STAY AWAKE, BE READY HÃY TỈNH THỨC VÀ SẴN SÀNG (2019)"/>
              </Link>
            </Fade>
          </div>
          <div className="d-block d-sm-none">
            <div className="txt-holder">
              <h4 className="film-title">
                <Link to="/stayawake">
                  STAY AWAKE, BE READY HÃY TỈNH THỨC VÀ SẴN SÀNG (2019)
                </Link>
              </h4>
              <div className="d-none d-sm-none d-md-block mb-4">
                <p><strong>Language:</strong> Vietnamese</p>
                <p><strong>Release Date:</strong> 23 May 2019 (France) </p>
                <p><strong>Director:</strong> Pham Thien An</p>
                <p><strong>Writer:</strong> Pham Thien An</p>
                <p><strong>Stars:</strong> Trung Dong Cao, Be Hieu, Vu Trong Tuyen</p>
              </div>
              <div className="text-white font-size-big">
                On a street corner a mysterious conversation among three young men at a street stalls. Meanwhile a traffic accident on a motorbike. The night brings together a sketch, a multi color frame of reality.
              </div>
            </div>
          </div>
        </div>
        <div className="card-shortfilm">
          <div className="img-holder">
            <Fade left>
              <Link to="/themute">
                <img loading="lazy" src="/files/banner-the-mute.jpg" alt="THE MUTE - CÂM LẶNG (2018) Film ngắn của đạo diễn Phạm Thiên Ân"/>
              </Link>
            </Fade>
          </div>
          <div className="txt-holder">
            <h4 className="film-title">
              <Link to="/themute">
              THE MUTE - CÂM LẶNG (2018)
              </Link>
            </h4>
            <div className="d-none d-sm-none d-md-block mb-4">
              <p><strong>Language:</strong> Vietnamese</p>
              <p><strong>Release Date:</strong> 20 June 2018 (USA)</p>
              <p><strong>Director:</strong> Pham Thien An</p>
              <p><strong>Writer:</strong> Pham Thien An</p>
              <p><strong>Stars:</strong> Nguyen Lam Quynh Han, Dan Le, Pham Thi Kim Ngoc</p>
            </div>
            <div className="text-white font-size-big">
            A girl tries to find the answer about love before her big day, in a rainy night.
            </div>
          </div>
        </div>
      </div>
    </Container>
  </section>
);

export default ShortFilms;
