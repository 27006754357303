import React from 'react';
import Fade from 'react-reveal/Fade';
import GatsbyLink from 'gatsby-link';
import './AboutUs.scss';

const AboutUs = () => (
  <section className="wrapper-about">
    <Fade bottom>
      <h2 className="title">
        <span>
          <strong>
            WHO WE ARE
          </strong>
        </span>
      </h2>
      <div className="desc">
        <strong>JK Film</strong> was founded in 2014 by a group of friends who share the same <strong>passion with cinema</strong>. With the desire to <strong>bring Vietnam’s cinema to the international scene</strong>, we focus on developing unique visions from Vietnam independent cinema, that <strong>reflecting different aspects of current Vietnamese contemporary between city life and country life.</strong>
      </div>
      <div style={{ paddingTop: 30, textAlign: 'center' }}>
        <GatsbyLink className="btn-primary" activeClassName="active" to="/members">Members</GatsbyLink>
      </div>
    </Fade>
  </section>
);

export default AboutUs;
