import React from 'react';
import { Container } from 'reactstrap';
import Link from 'gatsby-link';
import Fade from 'react-reveal/Fade';
import './FeatureFilm.scss';


const FeatureFilm = () => (
  <section className="wrapper">
    <Container>
      <Fade>
        <div className="section-feature">
          <h2 className="section-title">
            <Link to="/cocoon-shell">
              INSIDE THE YELLOW COCOON SHELL
            </Link>
          </h2>
          <div className="lead">BÊN TRONG VỎ KÉN VÀNG</div>
          <div className="pl-4 desc">
            The feature project has been selected for:
            <br/>
            <a className="link" arial-label="The feature project has been selected for: Cannes’ Focus CoPro (2019)" target="_blank" rel="noopener noreferrer" href="http://www.cannescourtmetrage.com/en/short-film-corner/nosevenements/focus-copro-focus-wip-1">
              Cannes’ Focus CoPro (2019)
            </a>
            <br/>
            <a className="link" arial-label="HBF Fall selection 2019" target="_blank" rel="noopener noreferrer" href="https://iffr.com/en/blog/hbf-fall-selection-2019">
            HBF Script and Development Scheme: Bright Future
            </a>
            <br/>
            <a className="link" arial-label="Open Doors Hub (2020)" target="_blank" rel="noopener noreferrer" href="https://www.locarnofestival.ch/pardo/pardo-live/today-at-festival/2020/06/OD_Hub_Lab.html">
            Open Doors Hub (2020)
            </a>
          </div>
        </div>
      </Fade>
    </Container>
  </section>
);

export default FeatureFilm;
